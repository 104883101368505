/* @flow */

import * as React from 'react'
import { ProductTemplate } from '../templates'

type Props = {
  location: {
    href: string,
    state: Object,
  },
}

export default function Form(props: Props) {
  const { location } = props
  return (
    <ProductTemplate
      url={location.href}
      title="Gagnez du temps chaque jour pour la vente et la gestion"
      media="illu_product-pointofsales_cashing.png"
      name="Le point de vente"
      label="Le point de vente"
      description="Avec notre caisse enregistreuse sur iPad, encaissez vos clients plus rapidement, gérez vos produits, suivez vos activités et votre comptabilité !"
      body={[
        {
          name: 'features',
          items: [
            {
              media: 'icon_product-pointofsales_cashing.png',
              title: 'Encaissement',
              content: 'Encaissez vos ventes en magasin rapidement grâce à la caisse tactile',
            },
            {
              media: 'icon_product-pointofsales_clientinfo.png',
              title: 'Suivi des clients',
              content: 'Fidélisez vos clients en analysant leurs comportements en boutique',
            },
            {
              media: 'icon_product-pointofsales_products.png',
              title: 'Gestion des stocks',
              content: 'Créez un catalogue personnalisé et gérez efficacement vos stocks',
            },
            {
              media: 'icon_product-pointofsales_activities.png',
              title: 'Contrôle des activités',
              content: "Retrouvez vos ventes et remboursements depuis l'historique des activités",
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'crater',
              media: 'screenshot_product_pointofsales_cashing.png',
              label: 'Encaissement',
              alt: 'encaissement en point de vente',
              title: 'Tout ce dont vous avez besoin pour encaisser vos ventes',
              content: `Depuis l'interface simple et intuitive du logiciel de caisse Wino, encaissez rapidement pour une expérience client optimale en magasin. Sélectionnez vos produits à encaisser dans votre catalogue ou pesez les grâce à votre balance connectée. Utilisez votre propre TPE ou réalisez des paiements mixtes pour encaisser la vente. Envoyez le ticket de caisse par mail au client. Vous pouvez également mettre en attente des tickets de caisse, imprimer des tickets provisoires, saisir des remises, faire des retours produits ou encore transformer des tickets de caisse en facture.`,
              feedback: {
                profile: 'photo_product-pointofsales_la-petite-flute.jpg',
                name: 'Yann Sidney',
                company: 'La Petite Flûte',
                content:
                  'L’utilisation de la caisse est vraiment appréciable, moi ça me convient bien !',
              },
            },
            {
              decoration: 'mexicali',
              media: 'screenshot_product_pointofsales_clientinfo.png',
              label: 'Suivi des clients',
              alt: 'suivi des clients en point de vente',
              title: 'Soyez proche de vos clients pour les fidéliser',
              content: `Enregistrez vos clients dans le répertoire du logiciel de caisse. Retrouvez toutes leurs informations de contact : mail, téléphone, adresses postales multiples. Visualisez leurs achats passés par vente et par produit vendu pour les conseiller selon leurs habitudes d'achat. Proposez des offres aux plus fidèles, ils seront ravis !`,
            },
          ],
        },
        {
          name: 'informationInsert',
          items: [
            {
              media: 'offline',
              title: 'Fonctionne hors-ligne',
              content:
                'Votre cave ou épicerie n’est pas reliée à Internet ? Aucun souci, vous pouvez utiliser votre logiciel de caisse sans connexion. Vos données seront sauvegardées automatiquement, une fois connecté à votre réseau Wi-Fi.',
            },
            {
              media: 'compliance',
              title: 'Conforme à la loi 2018',
              content:
                'Le logiciel de caisse Wino a été développé pour répondre aux exigences des normes de 2018. Depuis le logiciel de caisse, téléchargez votre attestation individuelle de conformité pour être en règle en cas de contrôle.',
              linkTo: '/logiciel-de-caisse-enregistreuse-obligatoire-en-2018',
              linkText: 'Lire nos articles sur la loi de finances 2018',
            },
          ],
        },
        {
          name: 'calloutCards',
          items: [
            {
              decoration: 'landing',
              media: 'screenshot_product_pointofsales_products.png',
              label: 'Gestion des produits',
              alt: 'gestion des produits sur le point de vente',
              title: 'Soyez maître de votre catalogue produits et de vos stocks',
              content:
                'Optez pour une gestion de catalogue pensée  sur-mesure pour les cavistes et épiciers. Profitez de fiches dédiées (vin, fromage, panier garni, vrac...) pour construire votre catalogue produits depuis la caisse tactile ou le tableau de bord. Gérez vos stocks de manière organisée et intelligente : ajoutez vos livraisons, vos pertes et créez des alertes quand vos stocks sont bas pour commander au bon moment et ainsi éviter la rupture de stock.',
              feedback: {
                profile: 'photo_product-pointofsales_l-intendance.jpg',
                name: 'Thomas Beschi',
                company: "L'Intendance",
                content:
                  'Avoir dans le catalogue des fiches produit dédiées pour le vin est un vrai plus pour mon activité de caviste',
              },
            },
            {
              decoration: 'clinch',
              media: 'screenshot_product_pointofsales_activities.png',
              label: 'Contrôle des activités',
              alt: 'contrôle des activités',
              title: 'Gérez sereinement votre comptabilité depuis la caisse enregistreuse',
              content: `Retrouvez tous vos tickets de caisse, ventes et remboursements, depuis l'historique des activités du logiciel de caisse. Maîtrisez les opérations de caisse quotidiennes : l'ouverture de la caisse chaque matin, les entrées et sorties d'espèces, les comptes en fin de journée avant la clôture du Z de caisse. Enfin, générez vos journaux de caisse mensuels pour la comptabilité de fin de mois. Des tâches à réaliser simples et rapides pour gagner du temps avec Wino !`,
            },
          ],
        },
        {
          name: 'winobox',
          title: 'Un matériel de caisse enregistreuse haut de gamme',
          media: 'photo_home_winobox.jpg',
          content: 'À l\'image de votre cave ou épicerie, nos experts vous conseillent dans le choix d\'un matériel esthétique, robuste et de qualité (iPad, imprimante, douchette).',
          buttonText: 'Obtenir un devis',
          buttonLink: '/devis',
        },
      ]}
    />
  )
}
